import '@fancyapps/fancybox';

import { ScrollSnapSlider } from 'scroll-snap-slider';

import Plyr from 'plyr';

import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import devAt from './inc/devAt';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';

const LazyLoad = require('vanilla-lazyload');
const Masonry = require('masonry-layout');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    dataStore.navToggle.toggleClass('isFixed', dataStore.docScroll >= 300);

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Actions Filter ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* Vue observe element intersection +++++++++++++++++++++++++++++*/
    observeIntersection('#actionsFilter', initActionsOverview, true);

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Event Listener +++++++++++++++++++++++++++-++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // Init Vue Menu Search
    $('#headBar > .navToggle').on('click touch', () => {
        initMenuSearch();
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /*--- Scroll to ID on Click ---*/
    $('a[href*="#"]:not([href="#"])').on('click touch', (e) => {
        if (window.location.pathname.replace(/^\//, '') === e.currentTarget.pathname.replace(/^\//, '')
                && window.location.hostname === e.currentTarget.hostname) {
            let hashTarget = $(e.currentTarget.hash);
            hashTarget = hashTarget.length ? hashTarget : $(`[name=${e.currentTarget.hash.slice(1)}]`);
            if (hashTarget.length) {
                $('html,body').animate({
                    scrollTop: hashTarget.offset().top
                }, 300);
                e.preventDefault();
            }
        }
    });

    /*--- Adventskalender scroll to ---*/
    if (dataStore.docWidth <= 991.98) {
        $(document).on('click touchstart', '.adventcalendar__door-link', () => {
            $('html,body').stop().animate({
                scrollTop: $('.advent-calendar-render').offset().top
            }, 300);
        });
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();

        // Toggle Burger class on scroll
        dataStore.navToggle.toggleClass('isFixed', dataStore.docScroll >= 300);
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();
}

$(window).on('load', () => {
    /* Snap Slider  ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.headerActions').length) {
        const headerActionsSlider = new ScrollSnapSlider(document.querySelector('.headerActions'));
        snapSliderNav(headerActionsSlider);
    }

    if ($('.headerSlider').length) {
        const headerSlider = new ScrollSnapSlider(document.querySelector('.headerSlider'));
        snapSliderNav(headerSlider);
    }

    if ($('.moreActionsSlider').length) {
        const moreActionsSlider = new ScrollSnapSlider(document.querySelector('.moreActionsSlider'));
        snapSliderNav(moreActionsSlider);
    }

    if ($('.productSlider').length) {
        const productSlider = new ScrollSnapSlider(document.querySelector('.productSlider'));
        snapSliderNav(productSlider);
    }

    const $fullWidthSlider = $('.fullWidthSlider');
    if ($fullWidthSlider.length) {
        for (let fullWidthSliderIndex = 0; fullWidthSliderIndex < $fullWidthSlider.length; fullWidthSliderIndex++) {
            const currentSlider = $fullWidthSlider[fullWidthSliderIndex];
            const fullWidthSlider = new ScrollSnapSlider(currentSlider);
            snapSliderNav(fullWidthSlider);
        }
    }

    /* plyr  ++++++++++++++++++++++++++++++++++++++++++*/
    initPlyr();

    $(window).on('borlabs:youtubeVimeoAccept', () => {
        initPlyr();
    });

    /* masonry  ++++++++++++++++++++++++++++++++++++++++++*/
    if ($(dataStore.grid).length) {
        dataStore.grid = new Masonry(dataStore.grid, {
            itemSelector: '.item',
            columnWidth: '.item',
            percentPosition: true,
            isOriginLeft: true,
        });
    }

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/

    if (document.querySelector('.fadeIn') !== null) {
        observeIntersection('.fadeIn', (entry) => {
            $(entry.target).not('.vis').addClass('vis');
        }, true);
    }

    if (document.querySelector('.fadeInRow') !== null) {
        observeIntersection('.fadeInRow', (entry) => {
            $(entry.target).find('> div, > li, > p, > a, > span, > svg').not('.vis').each((i, el) => {
                setTimeout(() => {
                    $(el).addClass('vis');
                }, 150 * i);
            });
        }, true);
    }
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    window.supportsWebp = false;
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        window.supportsWebp = true;
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

// Snap Slier Navi Function
function snapSliderNav(slider) {
    if ($(slider.element).length) {
        const sliderEl = $(slider.element);

        // Slider Indicator
        const indicator = sliderEl.parent().find('.scroll-snap-slider-indicator');
        if (indicator.length) {
            for (let indicatorIndex = 0; indicatorIndex < slider.element.childElementCount; indicatorIndex++) {
                let activeClass = '';
                if (indicatorIndex === 0) {
                    activeClass = ' active';
                }
                indicator.append(`<div class="dot${activeClass}" data-index="${indicatorIndex}"></div>`);
            }

            indicator.find('.dot').on('click touch', (e) => {
                slider.slideTo(parseInt($(e.currentTarget).attr('data-index'), 10));
            });
        }

        // Slider Arrows
        const prev = sliderEl.parent().find('.scroll-snap-slider-arrows .prev');
        const next = sliderEl.parent().find('.scroll-snap-slider-arrows .next');
        if (prev.length) {
            prev.on('click touch', () => {
                slider.slideTo(slider.slide - 1);
                if ((slider.slide - 1) === 0) {
                    prev.addClass('disabled');
                }
            });
        }

        if (next.length) {
            next.on('click touch', () => {
                prev.removeClass('disabled');
                slider.slideTo(slider.slide + 1);
            });
        }

        const setSelected = (e) => {
            if (prev.length && next.length) {
                if (e.detail === 0) {
                    prev.addClass('disabled');
                } else {
                    prev.removeClass('disabled');
                }

                if (e.detail === (slider.element.childElementCount - 1)) {
                    next.addClass('disabled');
                } else {
                    next.removeClass('disabled');
                }
            }

            if (indicator.length) {
                const curretnDot = $(indicator.find('.dot')[e.detail]);
                indicator.find('.dot').not(curretnDot).removeClass('active');
                curretnDot.addClass('active');
            }
        };

        slider.addEventListener('slide-pass', setSelected);
        slider.addEventListener('slide-stop', setSelected);
    }
}

// Vue Init Fucntion
function initActionsOverview() {
    // load map script and initialize map here
    scriptLoader(
        // eslint-disable-next-line no-undef
        [`${wp_urls.template_dir}/assets/dist/js/actionsOverview.js`]
    );
}

function initMenuSearch() {
    if (dataStore.initMenuSearchFlag === true) {
        // load map script and initialize map here
        dataStore.initMenuSearchFlag = false;
        scriptLoader(
            // eslint-disable-next-line no-undef
            [`${wp_urls.template_dir}/assets/dist/js/menuSearchInit.js`]
        );
    }
}

function initPlyr() {
    setTimeout(() => {
        Array.from(document.querySelectorAll('.plyr:not(.plyr--video)')).map((p) => new Plyr(p, {
            controls:
            ['play-large', 'play', 'progress', 'current-time', 'mute',
                'volume', 'captions', 'pip', 'airplay', 'fullscreen']
        }));
    }, 1000);
}
