class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');

        /* window ++++++++++++++++++++++++++++++++++++++++++*/
        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/
        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 768;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wp_urls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollEventListener = 'wheel';
        this.smoothDisabled = false;

        /* burger ++++++++++++++++++++++++++++++++++++++++++*/
        this.navToggle = $('.navToggle');

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = document.querySelector('.galerieImagesContainer');

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';
        this.supportsWebp = false;

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';

        /* Vue ++++++++++++++++++++++++++++++++++++++++++*/
        this.initMenuSearchFlag = true;
    }
}

export default (new DataStore());
